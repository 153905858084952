import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressRouterModule } from 'ngx-progressbar/router';

import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { I18nModule } from '@i18n/i18n.module';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment as ENV } from '@environments/environment';
import { NgMapsGoogleModule } from '@ng-maps/google';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    I18nModule,
    NgProgressModule,
    NgProgressRouterModule,
    NgMapsGoogleModule.forRoot({
      apiKey: ENV.googleMaps.apiKey,
      libraries: ['places'],
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: ENV.production }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
