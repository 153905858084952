export const environment = {
  production: false,
  environmentName: 'development',
  enabledServiceWorker: true,
  cargamos: {
    apiUrl: 'https://dev.services.partners.cargamos.com',
    apiKey: 'AIzaSyBUyeR0uKDkOpEAqtFk5RJL7RzcOtpoMZw',
  },
  googleMaps: {
    apiKey: 'AIzaSyBUyeR0uKDkOpEAqtFk5RJL7RzcOtpoMZw',
    searchLink: 'https://www.google.com/maps/search/?api=1',
  },
  storage: {
    apiUrl: 'https://storage.googleapis.com/storage/v1/b/postal-codes-kml/o',
    apiPartnersUrl: 'https://storage.googleapis.com/storage/v1/b',
  },
};
