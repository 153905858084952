import { ConfigResponse } from '@interfaces/responses/config.response';

export const SERVICE_PARTNERS: Array<string> = ['arriaga', 'gcmc'];

export enum ServicePartner {
  ARRIAGA = 'ARRIAGA',
  GCMC = 'GCMC',
}

export const PageConfig: {
  [k in keyof typeof ServicePartner]: ConfigResponse;
} = {
  ARRIAGA: {
    tenantId: 'arriaga-2sk9w',
    iconUrl:
      'https://storage.googleapis.com/valhalla-partners-dev.appspot.com/arriaga/arriaga_icon.svg',
    copyright: '<span> &copy; 2024 Arriaga. Todos los derechos reservados.</span>',
    clientText:
      'En Grupo Arriaga, nos enorgullecemos de contar con la confianza de empresas líderes que confían en nosotros para sus necesidades logísticas.',
    homeText:
      ' Grupo Arriaga, tu service partner estratégico para entregas rápidas y seguras en México',
    contact: {
      email: 'contacto@company.com',
      phone: '5525874587',
      address: '794 Mcallister St San Francisco, 94102',
    },
  },
  GCMC: {
    tenantId: 'gcmc-tupsp',
    iconUrl: 'https://storage.googleapis.com/valhalla-partners-dev.appspot.com/gcmc/icon.svg',
    copyright: '<span> &copy; 2024 GCMC. Todos los derechos reservados.</span>',
    clientText:
      'En GCMC, nos enorgullecemos de contar con la confianza de empresas líderes que confían en nosotros para sus necesidades logísticas.',
    homeText:
      'GCMC Logística, tu service partner estratégico para entregas rápidas y seguras en México',
    contact: {
      email: 'paqueteria.gcmc@gmail.com',
      phone: '5621716743',
      address: 'Dalia 92 6, Tamaulipas, Nezahualcóyotl 57300 Estado de México',
    },
  },
};
