import { Component, inject } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { PageConfig, SERVICE_PARTNERS, ServicePartner } from '@constants/service-partner.constant';
import { ConfigService } from '@services/config.service';

@Component({
  selector: 'cargamos-root',
  template: `
    <ng-progress [spinner]="false" [color]="'var(--cds-primary-color)'"></ng-progress>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {
  private readonly configService: ConfigService = inject(ConfigService);
  private readonly translate: TranslateService = inject(TranslateService);
  private readonly swUpdates: SwUpdate = inject(SwUpdate);
  private servicePartner: string = 'arriaga';

  constructor() {
    const url: string = window.location.href;

    SERVICE_PARTNERS.forEach(sp => {
      if (url.indexOf(sp) !== -1) {
        this.servicePartner = sp;
      }
    });
    this.configService.configPage = PageConfig[this.servicePartner.toUpperCase() as ServicePartner];

    this.setupLanguage();
    this.swUpdates.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(() => this.reloadApp());
  }

  private setupLanguage(): void {
    const defaultLanguage: string = 'es';
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use(defaultLanguage);
  }

  private reloadApp(): void {
    document.location.reload();
  }
}
