import { Injectable } from '@angular/core';
import { ConfigResponse } from '@interfaces/responses/config.response';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public tenantId!: string;
  public configPage!: ConfigResponse;
}
